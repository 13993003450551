import { api } from 'boot/axios';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { bearerStorageService } from 'src/shared/services';
import {Notify} from 'quasar';
import RESPONSE_MESSAGES from './response-messages.const';
import { logout } from 'src/shared/helpers/auth.helper';

const apiService = new (class ApiService {
  async get(endpoint: string, params = {}, headers: AxiosRequestConfig['headers'] = {}, getRawResponse = false, otherParams = {}) {
    try {
      const response = await api.get(endpoint, {
        withCredentials: true,
        headers: this.#getHeaders(headers),
        params,
        ...otherParams,
      })
      if (getRawResponse) {
        return response;
      }
      return response.data
    } catch (error) {
      this.#handleError(error as any);
    }
  }

  async post(endpoint: string, data: any, headers: AxiosRequestConfig['headers'] = {}) {
    try {
      const response = await api.post(endpoint, data, {
        withCredentials: true,
        headers: this.#getHeaders(headers)
      })
      return response.data
    } catch (error) {
      this.#handleError(error as any);
    }
  }

  async patch(endpoint: string, data: any, headers: AxiosRequestConfig['headers'] = {}) {
    try {
      const response = await api.patch(endpoint, data, {
        withCredentials: true,
        headers: this.#getHeaders(headers)
      })
      return response.data
    } catch (error) {
      this.#handleError(error as any);
    }
  }

  async delete(endpoint: string, data: any, headers: AxiosRequestConfig['headers'] = {}) {
    try {
      const response = await api.delete(endpoint, {
        withCredentials: true,
        headers: this.#getHeaders(headers),
        ...(data && { data })
      })
      return response.data
    } catch (error) {
      this.#handleError(error as any);
    }
  }

  #getHeaders(existingHeaders: AxiosRequestConfig['headers'] = {}) {
    const headers: AxiosRequestConfig['headers'] = {
      'Accept': 'application/json',
      ...existingHeaders
    }
    const bearerToken = bearerStorageService.get()
    if (bearerToken) {
      headers.Authorization = `Bearer ${bearerToken}`
    }
    return headers
  }

  #handleError(error: AxiosError<{ message: string }>) {
    if (error.code === 'ERR_CANCELED') {
      return
    }
    let message = error.response?.data?.message;
    if (!message) {

      if (error.response?.status && error.response.status >= 500) {
        message = RESPONSE_MESSAGES.INTERNAL_SERVER_ERROR;
      }

    }

    if (error.response?.status !== 422) {
      Notify.create({
        type: 'negative',
        position: 'bottom-right',
        message: message || error.message
      })
    }

    if(error.response?.status === 401) {
      logout();
    }

    throw error;
  }
})

export { apiService }
