import {storageApiService} from './../core/storage-api.service';

const bearerStorageService = new (class Storage {
  set(token: string) {
    storageApiService.set(BEARER_TOKEN_STORAGE_KEY, token);
  }

  get() {
    return storageApiService.get(BEARER_TOKEN_STORAGE_KEY);
  }

  clear() {
    storageApiService.remove(BEARER_TOKEN_STORAGE_KEY);
  }
})

const BEARER_TOKEN_STORAGE_KEY = 'BEARER';

export { bearerStorageService }
