import {storageApiService} from './../core/storage-api.service';

const appealItemStorageService = new (class Storage {
  setFilters(filters: object) {
    storageApiService.set('APPEAL_ITEM_FILTERS', JSON.stringify(filters));
  }

  setPage(page: number) {
    storageApiService.set('APPEAL_ITEM_PAGE', page.toString())
  }

  getFilters() {
    return storageApiService.get('APPEAL_ITEM_FILTERS');
  }

  getPage() {
    return storageApiService.get('APPEAL_ITEM_PAGE');
  }

  clear() {
    storageApiService.remove('APPEAL_ITEM_FILTERS');
    storageApiService.remove('APPEAL_ITEM_PAGE');
  }
})

export { appealItemStorageService }
