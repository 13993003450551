import {storageApiService} from './../core/storage-api.service';

const promoStorageService = new (class Storage {
  setFilters(filters: object) {
    storageApiService.set('PROMO_FILTERS', JSON.stringify(filters));
  }

  setPage(page: number) {
    storageApiService.set('PROMO_PAGE', page.toString())
  }

  getFilters() {
    return storageApiService.get('PROMO_FILTERS');
  }

  getPage() {
    return storageApiService.get('PROMO_PAGE');
  }

  clear() {
    storageApiService.remove('PROMO_FILTERS');
    storageApiService.remove('PROMO_PAGE');
  }
})

export { promoStorageService }
