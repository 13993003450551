import { IFetchMenuItemCounters, IFetchMenuItemsResponse } from 'src/shared/services/auth/interfaces';

export const menuItems: IFetchMenuItemsResponse = [
  {
    label: 'Профиль',
    name: 'profile',
    url: '/app/profile',
    icon: 'Profile.svg',
    counterClass: 'menu-counter-profile',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: [],
  },
  {
    label: 'Aналитика',
    name: 'analytics',
    url: '/app/analytics',
    icon: 'Chart.svg',
    counterClass: '',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: [
      {
        label: 'Рейтинги, отзывы и обращения',
        url: '/app/analytics',
        isActive: true
      },
      {
        label: 'Индексы качества',
        url: '/app/analytics/quality',
        isActive: true
      },
      {
        label: 'Аналитика продаж',
        url: '/app/analytics/sales',
        isActive: true
      },
      {
        label: 'Метрики промо',
        url: '/app/analytics/promo-metrics',
        isActive: true
      }
    ]
  },
  {
    'label': 'Упаковка товара',
    'url': '/app/package-dimensions',
    'icon': 'Box.svg',
    'counterClass': 'menu-counter-packing',
    'hasCounter': false,
    'isActive': false,
    'importantClass': '',
    'subItems': [],
    'name': 'packing'
  },
  {
    label: 'Промоакции',
    name: 'promo',
    url: '/app/promo',
    icon: 'Percentage.svg',
    counterClass: 'menu-counter-promo',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Помощь',
    url: '/help',
    icon: 'Help.svg',
    counterClass: '',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Антикоррупция',
    url: '/app/anti-corruption',
    icon: 'EmojiS.svg',
    counterClass: 'menu-counter-anticorruption',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Карточки товаров',
    url: '/card',
    icon: 'CT.svg',
    counterClass: 'menu-counter-card',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Карты<br> дизайн проектов',
    url: '/cdp',
    icon: 'CDP.svg',
    counterClass: 'menu-counter-cdp',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Этикетки на овощи',
    url: '/veg',
    icon: 'Veg.svg',
    counterClass: '',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Честный знак',
    url: '/gtin',
    icon: 'Honest_sign.svg',
    counterClass: 'menu-counter-gtin',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Журнал проверки<br> качества',
    url: '/journal',
    icon: 'ZhPK_Protocols.svg',
    counterClass: 'menu-counter-journal',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Отзывы и<br /> обращения',
    name: 'feedbacks',
    url: '/app/appeals',
    icon: 'Appeals.svg',
    counterClass: 'menu-counter-appeal',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Включения',
    url: '/includes',
    icon: 'Inclusion.svg',
    counterClass: 'menu-counter-includes',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Новости',
    url: '/news',
    icon: 'News.svg',
    counterClass: 'menu-counter-news',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Мои склады/РЦ',
    url: '/logistic',
    icon: 'Logistic.svg',
    counterClass: '',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Товары по складам',
    url: '/storages',
    icon: 'map_edit.svg',
    counterClass: '',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Добавить производителей',
    url: '/manufacturer',
    icon: 'Add_manufacturers.svg',
    counterClass: 'menu-counter-manufacturer',
    hasCounter: false,
    isActive: false,
    importantClass: '',
    subItems: []
  },
  {
    label: 'Поддержка',
    url: '/support',
    icon: 'Support.svg',
    counterClass: 'menu-counter-support',
    hasCounter: false,
    isActive: false,
    importantClass: 'menu-important-support',
    subItems: []
  }
]

export const counterItems: IFetchMenuItemCounters = {
  'menu-counter-support': '',
  'menu-counter-journal': '2',
  'menu-counter-packing': '21',
  'menu-counter-news': '',
  'menu-counter-card': '',
  'menu-counter-cdp': '30',
  'menu-counter-manufacturer': '',
  'menu-counter-profile': '',
  'menu-counter-promo': '',
  'menu-counter-appeal': ''
}
