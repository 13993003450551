import {defineStore} from 'pinia';

import {DEFAULT_STATE} from './auth-store.helper';
import {authService, bearerStorageService} from 'src/shared/services';
import {ENV_VARIABLES} from 'src/shared/helpers/const.helper';
import { AxiosError } from 'axios';
import { logout, redirectToLoginPage } from 'src/shared/helpers/auth.helper';
import { jwtDecode } from 'jwt-decode'
import { IAuthStoreState, IJwtPayload } from 'stores/auth/auth-store.interface';

export const useAuthStore = defineStore('auth', {
  state: () => DEFAULT_STATE,
  getters: {
    authState: (s) => s._authState,
    navigationMenu: (s) => s._navigationMenu,
    loadingState: (s) => s._loadingState as IAuthStoreState['_loadingState'],
    metaInfo: (s) => s._metaInfo as IAuthStoreState['_metaInfo'],
  },
  actions: {
    async authenticateUser() {
      if (location.pathname === '/app/provider-documents') {
        this._authState.acceptAsGuest = true;
        return false;
      }
      if (await this.handleNewBearerToken()) {
        this._authState.isAuthenticated = true
        return true;
      }
    },

    async handleNewBearerToken() {
      try {
        let jwt = '';
        if (ENV_VARIABLES.DISABLE_AUTHENTICATION) {
          jwt = bearerStorageService.get() as string;
        } else {
          jwt = await authService.getNewBearer();
        }
        bearerStorageService.set(jwt)
        this.setUserFullName(jwt);
        return true
      } catch (err) {
        console.log(err)
        redirectToLoginPage();
      }
    },

    async fetchInitialInformation() {
      this._loadingState.menu.isFetching = true
      try {
        await Promise.all([
          this.fetchMenuItems(true),
          this.fetchMetaInfo()
        ]);
      } finally {
        this._loadingState.menu.isFetching = false
      }
    },

    async fetchMenuItemCounters() {
      try {
        const counters = await authService.fetchMenuItemCounters();
        this._navigationMenu.elements.forEach((item: any) => {
          const counter = counters[item.counterClass];
          if (counter) {
            item.count = counter;
          } else {
            delete item.count;
          }
        })
      } finally {
      }
    },

    setUserFullName(jwt: string) {
      if (jwt) {
        const { data: { userFullName } } = jwtDecode<IJwtPayload>(jwt);
        this._navigationMenu.userFullName = userFullName;
      }
    },

    async logout() {
      let loggedOut = false;
      try {
        await authService.logout();
        loggedOut = true;
      } catch (error: any) {
        const errorWrapper = error as AxiosError<{ message: string }>
        if (errorWrapper.response?.status && errorWrapper.response?.status < 400) {
          loggedOut = true;
        }
      } finally {
        if (loggedOut) {
          logout(false);
        }
      }
    },

    async fetchMenuItems(withLoading = false) {
      if (withLoading) {
        this._loadingState.menu.isFetching = true
      }
      try {
        const [menuItems, counters] = await Promise.all([
          authService.fetchMenuItems(),
          authService.fetchMenuItemCounters()
        ]);
        menuItems.forEach((item: any) => {
          const counter = counters[item.counterClass];
          if (counter) {
            item.count = counter;
          }
        })
        this._navigationMenu.elements = menuItems;
      } finally {
        this._loadingState.menu.isFetching = false;
      }
    },

    async fetchMetaInfo() {
      this._loadingState.metaInfo.isFetching = true;
      try {
        this._metaInfo.data = await authService.fetchMetaInfo();
      } finally {
        this._loadingState.metaInfo.isFetching = false;
      }
    },

    getUserFullName() {
      return this._navigationMenu.userFullName;
    },

    getUserIdContr() {
      const jwt = bearerStorageService.get();
      if (jwt) {
        const { data: { idContr } } = jwtDecode<IJwtPayload>(jwt);
        return idContr;
      }
    },

    getUserType() {
      const jwt = bearerStorageService.get();
      if (jwt) {
        const { data: { userType } } = jwtDecode<IJwtPayload>(jwt);
        return userType;
      }
    }
  }
});

