import { AUTH_API_ENDPOINTS } from 'src/shared/endpoints';

import { apiService } from '../core/api.service'
import { cloneDeep } from 'lodash';
import {
  IFetchMenuItemsResponse,
  IFetchMenuItemCounters
} from 'src/shared/services/auth/interfaces';
import { ENV_VARIABLES } from 'src/shared/helpers/const.helper';
import { menuItems, counterItems } from './static-menu-items';
import { sleep } from 'src/shared/helpers/common.helper';

const isStaticMenuFetchingEnabled = ENV_VARIABLES.STATIC_MENU_FETCHING;

const authService = new (class AuthService {
  async getNewBearer(): Promise<string> {
    const { body } = await apiService.get(AUTH_API_ENDPOINTS.GET_BEARER)
    return body ;
  }

  async logout(): Promise<void> {
    return apiService.get(AUTH_API_ENDPOINTS.LOGOUT);
  }

  async fetchMenuItems(): Promise<IFetchMenuItemsResponse> {
    if (isStaticMenuFetchingEnabled) {
      return cloneDeep(menuItems);
    }
    const { body } = await apiService.get(AUTH_API_ENDPOINTS.FETCH_MENU_ELEMENTS)
    return body;
  }

  async fetchMenuItemCounters(): Promise<IFetchMenuItemCounters> {
    if (isStaticMenuFetchingEnabled) {
      return cloneDeep(counterItems);
    }
    const { body } = await apiService.get(AUTH_API_ENDPOINTS.FETCH_MENU_ELEMENT_COUNTERS)
    return body;
  }

  async fetchMetaInfo() {
    const { body } = await apiService.get(AUTH_API_ENDPOINTS.FETCH_META_INFO)
    return body;
  }
})

export { authService }
