import { appealStorageService, appealItemStorageService, bearerStorageService, missingDocumentsStorageService, promoStorageService } from 'src/shared/services';
import { ENV_VARIABLES } from 'src/shared/helpers/const.helper';

export function logout(withRedirect = true) {
  appealStorageService.clear();
  appealItemStorageService.clear();
  bearerStorageService.clear();
  missingDocumentsStorageService.clear();
  promoStorageService.clear();
  redirectToLoginPage(withRedirect);
}

export function redirectToLoginPage(withReferrer = true) {
  let redirectUrl = `${ENV_VARIABLES.APP_BASE_URL}/login`;
  if (withReferrer) {
    redirectUrl += `?referrer=${encodeURIComponent(window.location.href)}`;
  }
  window.location.href = redirectUrl;
}
