import {IAuthStoreState} from './auth-store.interface';

export const DEFAULT_STATE: IAuthStoreState = {
  _loadingState: {
    menu: {
      isFetching: false
    },
    metaInfo: {
      isFetching: false
    }
  },
  _authState: {
    isAuthenticated: false,
    isFailedToAuthenticate: false,
    acceptAsGuest: false
  },
  _navigationMenu: {
    elements: []
  },
  _metaInfo: {
    data: null
  }
}
