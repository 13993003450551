import { boot } from 'quasar/wrappers';
import axios, {AxiosHeaders, AxiosInstance, HeadersDefaults, RawAxiosRequestHeaders} from 'axios';
import { ENV_VARIABLES } from 'src/shared/helpers/const.helper';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const defaultHeaders: RawAxiosRequestHeaders | AxiosHeaders | Partial<HeadersDefaults> = {}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.myToken) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
  defaultHeaders.Authorization = `Bearer ${window.myToken}`
}



const api = axios.create({ baseURL: ENV_VARIABLES.API_BASE_URL, headers: {
    Accept: 'application/json'
}});

export default boot(({ app }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
});

export { api };
