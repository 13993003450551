const storageApiService = new (class StorageApi {
  set(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  get(key: string): string | null {
    return localStorage.getItem(key)
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }
})

export { storageApiService }
