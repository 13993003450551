import {storageApiService} from './../core/storage-api.service';
import {Sorting} from 'src/shared/interfaces/common.interface';

const missingDocumentsStorageService = new (class Storage {
  setSorting(column: string, order: Sorting) {
    storageApiService.set('MISSING_DOCUMENTS_SORTING', JSON.stringify({ [column]: order }));
  }

  setPage(page: number) {
    storageApiService.set('MISSING_DOCUMENTS_PAGE', page.toString())
  }

  getSorting(): object | null {
    const sorting =  storageApiService.get('MISSING_DOCUMENTS_SORTING');
    if (sorting) {
      try {
        return JSON.parse(sorting);
      } catch (_) {
        return null
      }
    }
    return null;
  }

  getPage() {
    const page = storageApiService.get('MISSING_DOCUMENTS_PAGE');
    if (page) {
      return parseInt(page, 10);
    }
  }

  clear() {
    storageApiService.remove('MISSING_DOCUMENTS_SORTING');
    storageApiService.remove('MISSING_DOCUMENTS_PAGE');
  }
})

export { missingDocumentsStorageService }
