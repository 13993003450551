export const ENV_VARIABLES = {
  APP_ASSETS_BASE_URL: process.env.APP_ASSETS_BASE_URL,
  APP_BASE_URL: process.env.APP_BASE_URL,
  API_BASE_URL: process.env.API_BASE_URL,
  TEST_SESSION_ID: process.env.TEST_SESSION_ID,
  DISABLE_AUTHENTICATION: parseInt(process.env.DISABLE_AUTHENTICATION as string, 10),
  STATIC_MENU_FETCHING: parseInt(process.env.STATIC_MENU_FETCHING as string, 10),
}

export enum MIME_TYPES {
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}
