import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/IndexPage.vue'),
      },
      {
        path: 'overdue-responses',
        component: () => import('pages/overdue-responses/OverdueResponsesPage.vue')
      },
      {
        path: 'profile',
        component: () => import('pages/profile/ProfilePage.vue')
      },
      {
        path: 'appeals',
        children: [
          {
            path: '',
            component: () => import('pages/appeals/AppealsPage.vue'),
          },
          {
            path: ':nomenclatureRef',
            component: () => import('pages/appeals/sub-pages/appeal-item/AppealItem.vue'),
          },
          {
            path: ':nomenclatureRef/appeal/:appealRef',
            component: () => import('pages/appeals/sub-pages/feedback-item/FeedbackItem.vue'),
          }
        ]
      },
      {
        path: 'package-dimensions',
        children: [
          {
            path: '',
            component: () => import('pages/package-dimensions/PackageDimensions.vue'),
          }
        ]
      },
      {
        path: 'analytics',
        children: [
          {
            path: '',
            component: () => import('pages/analytics/rating-and-appeals/AnalyticsPage.vue')
          },
          {
            path: 'quality',
            component: () => import('pages/analytics-quality/AnalyticsQualityPage.vue')
          },
          {
            path: 'sales',
            component: () => import('pages/analytics/sales/SalesAnalyticsPage.vue')
          },
          {
            path: 'promo-metrics',
            component: () => import('pages/promo-metrics/PromoMetricsPage.vue')
          },
        ]
      },
      {
        path: 'promo',
        children: [
          {
            path: '',
            component: () => import('pages/promo/PromoPage.vue')
          },
          {
            path: ':suffix',
            component: () => import('pages/promo/sub-pages/proposal/PromoProposal.vue')
          }
        ]
      },
      {
        path: 'missing-documents',
        children: [
          {
            path: '',
            component: () => import('pages/missing-documents/MissingDocumentsPage.vue')
          }
        ]
      },
      {
        path: 'support',
        children: [
          {
            path: '',
            component: () => import('pages/support/SupportPage.vue')
          },
          {
            path: ':id',
            component: () => import('pages/support/sub-pages/support-item/SupportItem.vue')
          }
        ]
      },
      {
        path: 'information',
        children: [
          {
            path: '',
            component: () => import('pages/information/InformationPage.vue'),
          },
          {
            path: 'whats-new',
            component: () => import('pages/information/sub-pages/whats-new/WhatsNewPage.vue')
          },
          {
            path: 'instructions',
            component: () => import('pages/information/sub-pages/video-instructions/VideoInstructionsPage.vue')
          },
          {
            path: ':page',
            component: () => import('pages/information/sub-pages/InformationItemPage.vue')
          }
        ]
      },
      {
        path: 'anti-corruption',
        children: [
          {
            path: '',
            component: () => import('pages/anti-corruption/AntiCorruptionPage.vue'),
          }
        ]
      },
      {
        path: 'order-sequence',
        children: [
          {
            path: '',
            component: () => import('pages/order-sequence/OrderSequencePage.vue'),
          }
        ]
      },
      {
        path: 'provider-documents',
        children: [
          {
            path: '',
            component: () => import('pages/provider-documents/ProviderDocumentsPage.vue'),
          }
        ]
      },
      {
        path: 'complaints',
        component: () => import('pages/complaints/ComplaintsPage.vue')
      },
      {
        path: 'inclusions',
        children: [
          {
            path: '',
            component: () => import('pages/inclusions/InclusionsPage.vue')
          },
          {
            path: ':nomenclatureRef',
            component: () => import('pages/inclusions/sub-pages/inclusions-by-nomenclature/InclusionsByNomenclature.vue'),
          },
          {
            path: ':nomenclatureRef/inclusion/:inclusionRef',
            component: () => import('pages/inclusions/sub-pages/inclusion-item/InclusionItem.vue'),
          },
        ]
      },
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
