<template>
    <q-card-section class="full-screen-height flex flex-column-order q-pa-none" v-if="loadingState.menu.isFetching || loadingState.metaInfo.isFetching">
      <q-inner-loading :showing="true">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-card-section>
    <router-view v-if="authState.isAuthenticated || authState.acceptAsGuest" v-show="!loadingState.menu.isFetching && !loadingState.metaInfo.isFetching" />
</template>

<script setup lang="ts">
import { onBeforeMount, watchEffect } from 'vue';
import {useQuasar} from 'quasar';

import {useAuthStore} from 'stores/auth/auth.store';

const quasarStore = useQuasar()
const { authenticateUser, authState, fetchInitialInformation, loadingState } = useAuthStore()

quasarStore.loading.show()

watchEffect(() => {
  if (authState.isAuthenticated || authState.acceptAsGuest) {
    quasarStore.loading.hide()
  }
})

onBeforeMount(async () => {
  if (await authenticateUser()) {
    await fetchInitialInformation();
  }
})


</script>
