import {storageApiService} from './../core/storage-api.service';

const appealStorageService = new (class Storage {
  setFilters(filters: object) {
    storageApiService.set('APPEAL_FILTERS', JSON.stringify(filters));
  }

  setPage(page: number) {
    storageApiService.set('APPEAL_PAGE', page.toString())
  }

  getFilters() {
    return storageApiService.get('APPEAL_FILTERS');
  }

  getPage() {
    return storageApiService.get('APPEAL_PAGE');
  }

  clear() {
    storageApiService.remove('APPEAL_FILTERS');
    storageApiService.remove('APPEAL_PAGE');
  }
})

export { appealStorageService }
